import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {createFundAdmin} from "../utils/reducer";
import { useLocation, useNavigate} from 'react-router-dom';
import {getSingleInvestor,addTransaction,getTransaction} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils.js';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TransactionSummary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location',location?.state);
    const investorid = location.state?.investorid;
    const investoruuid = location.state?.selectedinvestor?.investorid;
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [investorData, setInvestorData] = useState(location.state?.selectedinvestor);
    const [transactionData,setTransactionData] = useState({
        totaldeposits : 0,
        totalwithdrawals : 0,
        closingbalance : 0
    });
    const [filter , setFilter] = useState({});
    const [sorted , setSorted] = useState(false);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [tableKey, setTableKey] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    // transactions states
    const [transactionInfo,setTransactionInfo] = useState([]);
    const columns = [
        // {
        //   name: 'Transaction Id',
        //   selector: (row) => row?.transactionId || '' ,
        //   minWidth: '24%',
        //   maxWidth: '24%',
        // },
        {
            name: 'Date',
            selector: (row) => row?.transactionDate ? moment.utc(row?.transactionDate).format("MM-DD-YYYY") : '',
            minWidth: '15%',
            maxWidth: '15%',
        },
       
        ,{
            name: 'Notes',
            selector: (row) => {
                return (
                    <div className="clmn">
                            <span>{row?.notes}</span>
                        </div>
                );
            },
            minWidth: '25%',
            maxWidth: '25%',
          },
          ,{
            name: 'DEPOSITS',
            selector: (row) => row?.deposit ? formatCurrency(row?.deposit) : '',
            minWidth: '16%',
            maxWidth: '16%',
          },
          ,{
            name: 'withdrawals',
            selector: (row) => row?.withdraw ?  formatCurrency(row?.withdraw) : '',
            minWidth: '16%',
            maxWidth: '16%',
          },
          {
          name: 'Interest Gain',
          selector: (row) => row?.interestgain || '' ,
          minWidth: '14%',
          maxWidth: '14%',
        },
          ,{
            name: 'balance',
            selector: (row) => row?.balance ? formatCurrency(row?.balance) : '$0.00',
            minWidth: '14%',
            maxWidth: '14%',
          },
      ];

    //add withdrawal
   const [withdrawalData , setWithdrawalData] = useState({});
    const [formErrors, setFormErrors] = useState({});

     //add deposit
   const [depositData , setDepositData] = useState({});

  

    useEffect(() => {
        if(investorid){
            getTransactionData(currentPage,perPage,{});
        }
    },[]);
    // api's 
    const getTransactionData = (page,perpage,filter) => {
        dispatch(showLoader());
        let type = filter || {}
        getTransaction(page,perpage,investoruuid,type)
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    let responsedata = response?.result?.data
                    setTransactionInfo(responsedata);
                    setTotalRows(response?.result?.totalCount);
                    setTransactionData({
                        totaldeposits: response?.result?.totalDepositAmount,
                        totalwithdrawals: response?.result?.totalWithdrawAmount,
                        closingbalance: response?.result?.totalDepositAmount
                    });
                    // if(!filter?.fromdate && !filter?.todate && !filter?.sortby){
                    //     const totals = responsedata.reduce(
                    //         (acc, transaction) => {
                    //             if (transaction.type === 'DEPOSIT') {
                    //                 acc.totaldeposits += transaction.amount || 0;
                    //             } else if (transaction.type === 'WITHDRAW') {
                    //                 acc.totalwithdrawals += transaction.amount || 0;
                    //             }
                    //             return acc;
                    //         },
                    //         { totaldeposits: 0, totalwithdrawals: 0 }
                    //     );
                    //     const closingBalance = responsedata.length > 0 ? responsedata[0].balance : 0;
    
                       
                    // }
                    
                    dispatch(hideLoader());
                } else {
                    setTransactionInfo([]);
                    hideLoader([]);
                }
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    const addTransactionApi = (data) => {
        dispatch(showLoader());
        addTransaction(data)
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    setPopupOpen(true);
                    let filter = {};
                     setCurrentPage(1);
                   getTransactionData(1, perPage, filter);
                    setFilter({});
                }
                dispatch(showLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };


    
    const handleChange =(e,section)=>{
        let {name , value ,type} = e.target;
        if(section === 'withdrawal'){
            setWithdrawalData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }else if(section === 'deposit'){
            setDepositData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
       
    };
   
    const handleInvestorFocus = (e) => {
            setFormErrors({...formErrors,[e.target.name] : ''});
       
      }
    const handleAdd = ()=>{
        setFormErrors({});
        setWithdrawalData({});
        setDepositData({});
    }

    const handleSort =(e)=>{
        setFilter({
            ...filter,
            [e.target.name] : e.target.value,
        });
    };
    
    useEffect(()=>{
        console.log('filter',filter)
        if(filter?.fromdate && filter?.todate && filter?.sortby){
            getTransactionData(currentPage,perPage,filter);
            setSorted(true);
        }
    },[filter]);

    const handleClearSort = () => {
        let filter = '';
        setCurrentPage(1);
        getTransactionData(1, perPage, filter);
        setTableKey(prevKey => prevKey + 1);
        setSorted(false);
        setFilter({});
    };

    const handleAddWithdrawal = () => {
        console.log('investorData',withdrawalData);
        const fieldsToCheck = { 
            'withdrawalamount' : 'withdrawalamount','transactionDate' : 'transactionDate'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = withdrawalData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!withdrawalData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(withdrawalData.withdrawalamount == 0){
            error['withdrawalamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData =
            {"type":"WITHDRAW","notes":withdrawalData?.withdrawalnotes,"amount":withdrawalData?.withdrawalamount,"investorid":investoruuid,"transactionDate":withdrawalData?.transactionDate}
            apiData.amount =  apiData.amount ?  parseFloat(apiData.amount) :  null;
            document.getElementById('closewithdrawal').click();
            setMessage('Withdrawal Added Successfully')
            addTransactionApi(apiData);
            setSorted(false);
            setFilter({});
            // api call
        }
    };
    
    const handleAddDeposit = () => {
        console.log('investorData',depositData);
        const fieldsToCheck = { 
            'depositamount' : 'depositamount','transactionDate' : 'transactionDate'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = depositData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!depositData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(depositData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData = 
            {"type":"DEPOSIT","notes":depositData?.depositnotes,"amount":depositData?.depositamount,"investorid":investoruuid,"transactionDate":depositData?.transactionDate}
            apiData.amount =  apiData.amount ?  parseFloat(apiData.amount) :  null;
            document.getElementById('closedeposit').click();
            setMessage('Deposit Added Successfully')
            addTransactionApi(apiData);
            setSorted(false);
            setFilter({});
            // api call
        }
    };
    
    const closePopup = () => {
        setPopupOpen(false);
        
    };
    const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
         
        // Format table data for Excel
        const formattedData = transactionInfo.map(item => ({
            
            // 'TRANSACTION ID' : item?.transactionId || '',
            'DATE' : item.transactionDate ? moment.utc(item.transactionDate).format("MM-DD-YYYY") : '',
            'NOTES' : item?.notes || '',
            'DEPOSITS' : item?.deposit ? formatCurrency(item?.amount) : '' ,
            'WITHDRAWALS': item?.withdraw  ? formatCurrency(item?.amount) : '',
            'PERCENTAGE GAIN' : item?.percentagegain || '',
            'BALANCE' : item?.balance ? formatCurrency(item?.balance) : '',
            
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'transaction_history' + fileExtension);
    };

    const handlePageChange = (page) => {
        // if (!isSorting.current) {
            console.log('rannnn');
            let search = '';
            // let payload = { ...sortObj };
            if(filter?.fromdate && filter?.todate && filter?.sortby){
                getTransactionData(page,perPage,filter);
                setSorted(true);
            }else{
                getTransactionData(page,perPage,'');
            }
            setCurrentPage(page);
        // }
      };
        const handlePerRowsChange = async (newPerPage, page) => {
          // if (!isSorting.current) {
            console.log('i ran tooo')
            let filter = '';
            let search = '';
            // let payload = { ...sortObj };
            if(filter?.fromdate && filter?.todate && filter?.sortby){
                getTransactionData(page,newPerPage,filter);
                setSorted(true);
            }else{
                getTransactionData(page,newPerPage,'');
            }
            setCurrentPage(page);
            setPerPage(newPerPage);
          // }
        };


   
      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };
      return (
        <div id="layout-wrapper">
 {isPopupOpen && (
        <PopupMessage
          type={'success'}
          message={message}
          onClose={closePopup}
        />
      )}
{loading ? <Loader/> : null} 
           <MainHeader />
         
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement fund_management transaction_page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">transaction</h4>
                                <a href="#" id="navigateManagerBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                <div className="steps">
                                    <div className="deposit_withdrawl">
                                    <button type="button"
                                     disabled={transactionData?.closingbalance === 0 ? true : false} style={{'opacity' : transactionData?.closingbalance === 0 ? 0.6 : 1}}
                                      onClick={handleAdd} data-bs-target="#addwithdrawal" data-bs-whatever="@mdo" data-bs-toggle="modal" className="create_btn new_button mb-1"> Withdrawal<span className="material-symbols-outlined">payments</span></button>
                                    <button type="button"  onClick={handleAdd} data-bs-target="#adddeposit" data-bs-whatever="@mdo" data-bs-toggle="modal" className="create_btn new_button"> Deposit<span className="material-symbols-outlined">savings</span></button>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info"><span className="text-type">Total Deposits</span>
                                    <span className="text">{transactionData?.totaldeposits ? formatCurrency(transactionData?.totaldeposits) : '$0.00'}</span>
                                    
                                    </div>
                                    <span className="material-symbols-outlined">savings</span>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text-type">Total Withdrawals</span>
                                    <span className="text">{transactionData?.totalwithdrawals ? formatCurrency(transactionData?.totalwithdrawals) : '$0.00'}</span>
                                   
                                    </div>
                                    <span className="material-symbols-outlined">payments</span>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text-type">Closing Balance</span>
                                    <span className="text">{transactionData?.closingbalance ? formatCurrency(transactionData?.closingbalance) : '$0.00'}</span>
                                   
                                    </div>
                                    <span className="material-symbols-outlined">account_balance_wallet</span>
                                    </div>
                                   </div>
                                   <div className='search'>
                                        <h5 className="title mt-0 mb-0">{investorData?.investorid ? investorData?.investorid : ''} - {investorData?.firstname ? (investorData?.firstname + ' ' + investorData?.lastname) : ''}</h5>
                                        <div className="d-flex align-items-center form-group">
                                        <div className="d-flex align-items-center">
                                            <label>From</label>
                                            <input id="fromdate" type="date" placeholder="mm-dd-yyy" name="fromdate" onChange={handleSort}  class="form-control null" value={filter?.fromdate || ''}/>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <label>To</label>
                                            <input id="todate" type="date" placeholder="mm-dd-yyy" name="todate" onChange={handleSort}  class="form-control null" value={filter?.todate || ''}/>
                                            </div>
                                        <div className="d-flex align-items-center">
                                            <label>Sort By</label>
                                            <select id="sortby" name="sortby" value={filter?.sortby || ''} onChange={handleSort} className="form-control" tabindex="1">
                                                <option value="">Select</option>
                                                <option value="Ascending">Ascending</option>
                                                 <option value="Descending">Descending</option>
                                            </select>
                                            </div>
                                            <button type="button" disabled={transactionInfo?.length == 0 ? true : false} style={{'opacity' : transactionInfo?.length == 0 ? 0.6 : 1}} onClick={handleExportToExcel} className="create_btn new_button ms-2">Export Statement<span className="material-symbols-outlined">arrow_outward</span></button>
                                        </div>
                                    </div>
                                    {sorted &&
                                        <div className='search_result'>
                                            <h4>Sort results</h4>
                                            <button type="button" className="border_btn" onClick={handleClearSort}>Clear Sort </button>
                                        </div>
                                    }
                                <div class="table-responsive">
                                    {transactionInfo?.length > 0 && 
                                      <DataTable 
                                      key={tableKey}
                                      columns={columns}
                                      data={transactionInfo}
                                      paginationTotalRows={totalRows}
                                      paginationPerPage={perPage}
                                      paginationDefaultPage={currentPage}
                                      pagination
                                      paginationServer
                                      customStyles={customStyles}
                                      onChangePage={handlePageChange}
                                      onChangeRowsPerPage={handlePerRowsChange}
                                      paginationRowsPerPageOptions={[10, 15, 30, 40]}
                                      paginationComponentOptions={{
                                          rowsPerPageText: 'Per Page',
                                      }}
                                      persistTableHead
                                      noDataComponent="No results found" // Show a message when there are no rows
                                  />
                                    }
                              
                                        </div>

                                   

                                    {transactionInfo?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="addwithdrawal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">ADD WITHDRAWAL</h5>
                                        <button type="button" className="pop_close" id="closewithdrawal" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>WITHDRAWAL AMOUNT</label>
                                            <CurrencyInput
                                                         id={'withdrawal_amount'}
                                                         name={'withdrawalamount'}
                                                         placeholder="Enter Amount"
                                                         value={withdrawalData?.withdrawalamount}
                                                         className={`form-control null ${formErrors.withdrawalamount && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         prefix="$"
                                                         onFocus={handleInvestorFocus}
                                                         onValueChange={(value, name, values) => {
                                                            setWithdrawalData((prev) => {
                                                                if (values.value <= transactionData.closingbalance) {
                                                                    return {
                                                                        ...prev,
                                                                        withdrawalamount: values.value,
                                                                    };
                                                                } else {
                                                                    return prev; 
                                                                }
                                                            });
                                                         }}
                                                       />
                                            </div>
                                        </div>
                                              <div className="col-md-12">
                                                  <div className="form-group">
                                                      <label>Date</label>
                                                      <input id="withdraw_date" type="date" className={`form-control  ${formErrors?.transactionDate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="transactionDate" onChange={(e)=>{handleChange(e,'withdrawal')}} value={withdrawalData?.transactionDate || ''}  onFocus={handleInvestorFocus}/>
                                                  </div>
                                              </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Notes</label>
                                            <textarea  id="withdrawalnotes" name='withdrawalnotes'  value={withdrawalData?.withdrawalnotes || ''} onChange={(e)=>{handleChange(e,'withdrawal')}} className="form-control null"  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        
                                           
                                               <div className="col-md-12">
                                            <div className="form-group">
                                                
                                                <button id="denial-btn" onClick={handleAddWithdrawal}  type="button"  className="fs_btn">ADD</button>
                                                
                                                        
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="adddeposit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">ADD DEPOSIT</h5>
                                        <button type="button" className="pop_close" id="closedeposit" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>DEPOSIT AMOUNT</label>
                                            <CurrencyInput
                                                         id={'depositamount'}
                                                         name={'depositamount'}
                                                         placeholder="Enter Amount"
                                                         value={depositData?.depositamount}
                                                         className={`form-control null ${formErrors.depositamount && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         prefix="$"
                                                         onFocus={handleInvestorFocus}
                                                         onValueChange={(value, name, values) => {
                                                            setDepositData((prev) => ({
                                                                ...prev,
                                                                ['depositamount']:  values.value,
                                                                
                                                            }));
                                                         }}
                                                       />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                                  <div className="form-group">
                                                      <label>Date</label>
                                                      <input id="deposit_date" type="date" className={`form-control  ${formErrors?.transactionDate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="transactionDate" onChange={(e)=>{handleChange(e,'deposit')}} value={depositData?.transactionDate || ''} onFocus={handleInvestorFocus} />
                                                  </div>
                                              </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Notes</label>
                                            <textarea id="depositnotes" name='depositnotes' value={depositData?.depositnotes || ''} onChange={(e)=>{handleChange(e,'deposit')}}  className="form-control null"  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        
                                           
                                               <div className="col-md-12">
                                            <div className="form-group">
                                                
                                                <button id="denial-btn" onClick={handleAddDeposit} type="button"  className="fs_btn">ADD</button>
                                                
                                                        
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default TransactionSummary;