import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {createFundManagementAdmin , getSingleFundManagementAdmin, updateFundManagementAdmin} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import LocationSearch from './LocationsSeachBox';

const CreateFundManagement = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location',location?.state);
    const { selectedfund } = location ;
    const getMode = location.state?.mode;
    console.log('selectedfund---->',selectedfund);
    const fundid = location.state?.fundId;
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [type , setType] = useState(location?.state?.mode ? 'update' : 'add');
    const [message , setMessage] = useState('Updated Successfully');
    const [fundDataOld,setFundDataOld] = useState({});
    const [fundBool,setFundBool] = useState(false);
    const [pastData,setPastData] = useState({});
    const [fundData,setFundData] = useState({
        disburseinterest: false,
        disbursefee: false,
        disbursefeeinterest : false,
        disbursestripfee : false,
    });
    const [fundTypeOptions, setFundTypeOptions] = useState(['Preferred']);
    const [fundLevelOptions, setFundLevelOptions] = useState(['Level 1', 'Level 2', 'Level 3', 'Level 4']);
    const [selectedFundPlace , setSelectedFundPlace] = useState(null);
    const [fundAddressData,setFundAddressData] = useState({});
    const [selectedPlace, setSelectedPlace] = useState(null);
    const fundNamesArr = location?.state?.fundNames || [];

    const [errors, setErrors] = useState({});
    // const jsdom = require("jsdom");
    // const { JSDOM } = jsdom;
    

        const compareTerms = () => {
            // const getKeys= Object.keys(fundData);
            const allowedKeys = ["name","fundid"];
            
            const bool1 = allowedKeys.every(key => fundData[key] === fundDataOld[key]);
            const temp1 = document.createElement('div');
            temp1.innerHTML = fundData?.template;
            const temp2 = document.createElement('div');
            temp2.innerHTML = fundDataOld?.template;
            const equal = temp1.isEqualNode(temp2);

            // const dom1 = root1.toString();
            // const dom2 = root2.toString();
           
            // const bool2 = dom1.window.document.isEqualNode(dom2.window.document);
            if(bool1 && equal){
                return true;
            }else{
                return false;
            }
            
        }

        useEffect(() => {
            console.log('fundData',fundData);
            console.log('old fund data',fundDataOld);
            // if(type === "update"){
            //     const areEqual = compareTerms();
            //     console.log("equal",areEqual);
            //     setFundBool(areEqual);
            // }
            
        },[fundData]);


        useEffect(() => {
            console.log("sheet id",fundid);
            callGetSingleFund();
        },[getMode]); 
    
        const callGetSingleFund = () => {
            dispatch(showLoader());
            
            getSingleFundManagementAdmin(fundid).then(function (response) {
                if (response?.result?.data[0]) {
                    setPastData(response?.result?.data[0]); 
                    setFundDataOld(response?.result?.data[0]); 
                    if(getMode){
                        console.log('single data---->',response?.result);
                        setFundData(response?.result?.data[0]);
                        //setSelectedPlace(response?.result?.data[0]?.address)
                        setSelectedFundPlace(response?.result?.data[0]?.address);
                    }
                    
                    
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("single account error", err);
            })
        }

        useEffect(() => {
            if (selectedFundPlace) {  // Only set the address if there's a selected place
                setFundData(prevData => ({
                    ...prevData,
                    address: selectedFundPlace, // Update the address field
                }));
            }
        }, [selectedFundPlace]);

        // const getAllFundsData = () => {
            
        //     dispatch(showLoader());
        //     getAllFundsAdmin().then(function (response) {
        //         if (response?.result) {
        //             if(Object.keys(pastData)?.length === 0){
        //                 setPastData(response?.result);
        //             }
        //             setFundData(response?.result);
        //             console.log('getAllFundsAdmin data---->',response?.result);
        //         }
        //         dispatch(hideLoader());
        //     }).catch((err) => {
        //         dispatch(hideLoader());
        //         console.error.bind("getAllFundsAdmin error", err);
        //     })
        // }

        // useEffect(() => {
        //     getAllFundsData();
        // },[])

    
    const handleChange = (e) => {
        //console.log('e.target', e.target.name, e.target.value, e.target.label);
        let name = e.target.name;
        let value = e.target.value;
        //const {name , value} = e.target;
       
        // if(name === "fundid"){
        //     value = value.replace(/[^A-Za-z0-9-]/g, '');
        // }
        setFundData((prev) => ({
            ...prev,
            [name]: value,
        }));
        
    };

    const handleFundCheckBoxes = (e) => {
        let {name, value , checked} = e.target;
        console.log(`${name} changed to ${checked}`);
        setFundData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
        //setFundData({ ...fundData, [name]: checked });
    }
    

    function cleanString(str) {
        // Remove symbols and spaces, and convert to lowercase
        return str?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    }
    
    function inputValueMatches(inputValue1,inputValue2) {
        
        const cleanedInputValue1 = cleanString(inputValue1);
        const cleanedInputValue2 = cleanString(inputValue2);
        // Check if any object in arr has both cleaned sheetName and programType properties that match the cleaned inputValue
        // if (Array.isArray(pastData)) {
            // fundData is an array
            //return pastData?.data?.some(item => cleanString(item?.sheetName) === cleanedInputValue1 && cleanString(item?.programType) === cleanedInputValue2);
        // } 
         // Check if any object in pastData.data has both cleaned sheetName and programType properties that match the cleaned inputValue
        if (Array.isArray(pastData?.data)) {
            return pastData?.some(item => 
                cleanString(item.name) === cleanedInputValue1 && cleanString(item?.programType) === cleanedInputValue2
            );
        }
        return false;
        
    }


    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          console.log("Error class added to element:", inputElement);
        }
      };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    const handleSave = ()=>{
        //navigate(-1);
        // console.log("existing data",fundData);
        const fieldsToCheck = {
             name : 'name',
             fundid : 'fundid'
        };
        let error = {};
        //const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            
            if (!fundData?.[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
            if (fieldName === "name" && fundData?.[fieldName] && Array.isArray(fundNamesArr)) {
                const normalizedFundName = fundData[fieldName].toLowerCase().replace(/\s+/g, '');
                const oldName = fundDataOld[fieldName]?.toLowerCase().replace(/\s+/g, '');
                let nameExists = false;
            
                // Check for duplicates only if adding or if the name differs from the old name
                if (type === "add") {
                    nameExists = fundNamesArr.some(name => {
                        const normalizedExistingName = name.toLowerCase().replace(/\s+/g, '');
                        return normalizedExistingName === normalizedFundName;
                    });
                } else if (normalizedFundName !== oldName) {
                    nameExists = fundNamesArr.some(name => {
                        const normalizedExistingName = name.toLowerCase().replace(/\s+/g, '');
                        return normalizedExistingName === normalizedFundName;
                    });
                }
            
                if (nameExists) {
                    error[fieldName] = "Name Already Exists!";
                }
            }
            
        };

        

        setErrors(error);

        if(Object.keys(error).length === 0){
            // if(type !== "update"){
            //     dispatch(showLoader());
            //     setPopupOpen(true);
            //     setMessage("Sheet Name or Program Type already Exists.")
            //     dispatch(hideLoader());
            // }else{
                //let mainData = {...fundData, 'template': template}
                // if(fundData?.termSheet == 'new' || fundData?.termSheet == ''){
                //     mainData = {...fundData, 'termSheet':fundData?.sheetName }
                //     setFundData((prev) => ({
                //         ...prev,
                //         ['termSheet']: fundData?.sheetName,
                //     }));
                // }

                // Set the address before making the API call
                const updatedFundData = {
                    ...fundData,
                    address: selectedFundPlace || fundData?.address // Use selectedPlace or existing address
                };
                if(type === "add"){

                    
                    //let mainData = {...fundData};
                    console.log('data to api----->',updatedFundData)
                    dispatch(showLoader());
                    createFundManagementAdmin(updatedFundData).then(function (response) {
                            console.log('responseresponse',response);
                            if(response.result){
                                
                                setPopupOpen(true);
                                setMessage(type === 'update' ? 'Updated Successfully' : 'Created Successfully');
                            
                            }
        
                            dispatch(hideLoader());
                        }).catch((err) => {
                            dispatch(hideLoader());
                            console.error.bind("Login error", err);
                        })
                }
                else{
                    // let { name, fundid, disburseinterest, disbursefee, disbursefeeinterest, disbursestripfee } = fundData;
                    // let updatedData = { name, fundid, disburseinterest, disbursefee, disbursefeeinterest, disbursestripfee };
                    
                    // Destructuring to exclude specific keys
                    const { _id, appname, created, status, createdBy, ...updatedData } = updatedFundData;
                    console.log('Data to API ----->', updatedData);
                    dispatch(showLoader());
                    updateFundManagementAdmin(updatedData,fundid).then(function (response) {
                            console.log('responseresponse',response);
                            if(response.result){
                                
                                setPopupOpen(true);
                                setMessage(type === 'update' ? 'Updated Successfully' : 'Created Successfully');
                            
                            }
        
                            dispatch(hideLoader());
                        }).catch((err) => {
                            dispatch(hideLoader());
                            console.error.bind("Login error", err);
                    })
                }
               
                
        }

             
        
    }

    const handleBack = (e) => {
        e.preventDefault(); 
        navigate(-1);
    }

    
    const closePopup = () => {
        setPopupOpen(false);
        navigate('/fundmanagement');
    };

   

    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message === "Sheet Name or Program Type already Exists." ? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management create_fundmanagement">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{type === 'update' ? 'Update Fund Mangement' : 'Create Fund Mangement'} </h4>
                                <a href="#" id="navigateFundManagerBack" onClick={(e) => handleBack(e)} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                    <div className='search mb-4'>
                                <h5 className="title mt-0 mb-0"> CREATE FUND</h5>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Select Term Sheet</label>
                                                            <select name='termSheet' onChange={handleChange} className={`form-control ${errors?.termSheet && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, termSheet: null }) }}>
                                                                {termSheet.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                               
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Fund Id</label>
                                                            <input id="fundManagerId" name='fundid' value={fundData?.fundid} onChange={handleChange} type="text" className={`form-control ${errors?.fundid && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, fundid: null }) }} placeholder="Enter Fund Id" />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Fund Name</label>
                                                            <input id="fundName" type="text" name="name" placeholder="Enter Fund Name" className={`form-control ${errors?.name && 'invalid-input'}`}
                                                            onChange={handleChange}  value={fundData?.name} 
                                                            onFocus={() => { setErrors({ ...errors, name: null }) }} readOnly={(fundData?.default === true) ? true : false}
                                                            style={{backgroundColor : (fundData?.default === true) ? 'rgb(233, 236, 239)' : '' }}/>
                                                            {errors?.name && errors?.name === "Name Already Exists!" && <p style={{color : 'red'}}>{errors?.name}</p>}
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Fund Type</label>
                                                            <select 
                                                                id="fundType" 
                                                                name="type" 
                                                                className="form-control" 
                                                                tabIndex="1" 
                                                                onChange={handleChange} 
                                                                value={fundData?.type}
                                                            >
                                                                <option value="">Select</option>
                                                                {fundTypeOptions.map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Fund Level</label>
                                                            <select 
                                                                id="fundLevel" 
                                                                name="level" 
                                                                className="form-control" 
                                                                tabIndex="1" 
                                                                onChange={handleChange} 
                                                                value={fundData?.level}
                                                            >
                                                                <option value="">Select</option>
                                                                {fundLevelOptions.map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Fund Address</label>
                                                            {/* <input id="contactFirstname" type="text" name="address" placeholder="Add" class="form-control capitalize-text null" 
                                                            onChange={handleChange}  value={fundData?.address}/> */}
                                                            <LocationSearch name={'address'} setAddressData={setFundAddressData}  setSelectedPlace={setSelectedFundPlace} selectedPlace={selectedFundPlace || fundData?.address} simpleAddress = {true}/>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label></label>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Disburse Interest</span>
                                                                        <input id="disburseInterest" className="toggle-checkbox" type="checkbox" name="disburseinterest"
                                                                            onChange={handleFundCheckBoxes} checked={fundData?.disburseinterest}/>
                                                                        <div id="disburseinterest-toggle" className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Disburse Fee</span>
                                                                        <input id="disburseFee" className="toggle-checkbox" type="checkbox" name="disbursefee"
                                                                        onChange={handleFundCheckBoxes} checked={fundData?.disbursefee}/>
                                                                        <div id="disbursefee-toggle" className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Disburse Fee Interest</span>
                                                                        <input id="disburseFeeInterest" className="toggle-checkbox" type="checkbox" name="disbursefeeinterest"
                                                                        onChange={handleFundCheckBoxes} checked={fundData?.disbursefeeinterest}/>
                                                                        <div id="disbursefeeinterest-toggle" className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Disburse Strip Fee</span>
                                                                        <input id="disburseStripFee" className="toggle-checkbox" type="checkbox" name="disbursestripfee"
                                                                        onChange={handleFundCheckBoxes} checked={fundData?.disbursestripfee}/>
                                                                        <div id="disbursestripfee-toggle" className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Disburse Prinicipal</span>
                                                                        <input id="disbursePrincipal" className="toggle-checkbox" type="checkbox" name="disburseprincipal"
                                                                        onChange={handleFundCheckBoxes} checked={fundData?.disburseprincipal}/>
                                                                        <div id="disburseprincipal-toggle" className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Program Type</label>
                                                            <select id="termSheetManagerprogramType" name='programType' value={fundData?.programType} onChange={handleChange} className={`form-control ${errors?.programType && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, programType: null }) }}>
                                                            {programType?.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <ValuesDropdown onChange={handleChangeValues}/> */}
                                                </div>
                        
                                                {/* <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                          
                                                            <EditorEdit content={template} setContent={setTemplate} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} type={type} formVariables={formVariables} formValuesOption={formValuesOption} valueDependency = {valueDependency} />
                                                        </div>
                                                        {fundData?.sheetName === 'Underwriting Term Sheet' ? '' : 
                                                         <label className="toggle">
                                                         <input id="termSheetManagerWhiteLabel" className="toggle-checkbox" disabled={true} onChange={(e)=>handleCheckBox(e)} checked={fundData?.whiteLabel} type="checkbox" />
                                                         <div className="toggle-switch"></div>
                                                         <span className="toggle-label">White Label</span>
                                                     </label>
                                                        }
                                                       
                                                    </div>
                                                </div> */}
                                                <div className="btn-group mt_10">
                                                 
                                                {type === 'update' && <button id="updateFundManager" onClick={(e) => {if (!fundBool) { e.preventDefault();  handleSave() }}} className="fs_btn mr_10" disabled = {fundBool} 
                                                 style={{
                                                    opacity: fundBool ? '0.6' : '1',
                                                    pointerEvents: fundBool ? 'none' : 'auto',
                                                    cursor: fundBool ? 'not-allowed' : 'pointer',
                                                }}> Update </button>}
                                                    {type !== 'update' && <button id="createFundManager" onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" > Create</button>}
                                                    {/* <a href="#" onClick={(e) => {e.preventDefault(); handleDelete()}} className="border_btn">Delete</a> */}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateFundManagement;