import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { achReportApi } from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector } from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';

const AdminAch = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const configdata = useSelector(state => state.configdata);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({nsf : false});
    const [errors, setErros] = useState({});

    const handleChange = (e) => {
        let { name, value, type } = e.target
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? e.target.checked : value
        })
    };

    const handleFocus = (e) => {
        let { name } = e.target
        setErros({
            ...errors,
            [name]: '',
        })
    };

    const handleDownload = () => {
        const fieldsToCheck = { 
            "fromdate" : "fromdate" , "todate" : "todate" , "effectivedate" : "effectivedate"
        };
        let errors = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = formData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!formData[fieldName]?.trim()) {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        setErros(errors);
        if (Object.keys(errors).length === 0) {
            handleAchAPi();
        }
        
    };
    const handleAchAPi = () => {
        dispatch(showLoader());
       
        achReportApi(formData).then(function (response) {
            if (response?.result) {
                const fileUrl = configdata?.common?.resourcesCloudfront + response?.result;
                DownaloadFile(response?.result);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };
    const DownaloadFile = async (docURl) => {
        try {
            console.log('configdatadocURL',docURl, configdata?.common?.resourcesCloudfront);
          const response = await fetch(configdata?.common?.resourcesCloudfront+docURl);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
    
          const blob = await response.blob();
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${docURl}`; 
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          URL.revokeObjectURL(link.href);
          console.log('File downloaded successfully');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };



    return (
        <div id="layout-wrapper">
            {loading ? <Loader /> : null}


            <MainHeader />
            <SideMenu />
            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">ACH</h4>
                                    <a id="navigatePrivacy" className="back_btn" href="#" onClick={(e) => { e.preventDefault(); navigate('/pipeline'); }}><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                                </div>

                                <div className="card">
                                    <div className="card-body ">
                                        <div className="form_section">
                                            <div className='row'>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>From Date</label>
                                                    <input id="effective_date" type="date" className={`form-control  ${errors?.fromdate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="fromdate" onChange={handleChange} value={formData?.fromdate || ''}  onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>To Date</label>
                                                    <input id="todate" type="date" className={`form-control  ${errors?.todate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="todate" onChange={handleChange} value={formData?.todate || ''} onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Effective Date</label>
                                                    <input id="effectivedate" type="date" className={`form-control  ${errors?.effectivedate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="effectivedate" onChange={handleChange} value={formData?.effectivedate || ''} onFocus={handleFocus}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3 interest_only">
                                                <div className="form-group">
                                                    <label></label>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <label className="toggle mb-0 mt-1"><span className="toggle-label">NSF</span><input id="autoach" name='nsf' onChange={handleChange} checked={formData?.nsf} className="toggle-checkbox" type="checkbox" /><div className="toggle-switch"></div></label>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="btn-group mt_30">
                                            <button id="generateach" onClick={handleDownload} className="fs_btn" style = {{'opacity' : (false) ? '0.6' : '1'}}  disabled={(false) ? true : false}>DOWNLOAD</button>
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <HomeFooter />
            </div>



        </div>
    );
};

export default AdminAch;